// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-transformation.css";
@import 'tippy.js/dist/tippy.css';
@import "src/theme/z2s.scss";

/* Null styles */
html {
  font-size: 18px;
}

ul, li  {
  list-style: none;
  padding: 0;
  margin: 0;
}

:root {

  color: $z2s-color-black;

  ion-modal {
    //--height: 85%;
    &.fakeModal {
      display: none;
    }

    &.modal-places {
      z-index: 0 !important;
      pointer-events: none !important;
      &::part(backdrop) {
        pointer-events: none !important;
      }
    }

    &.modal-places::part(content) {
      box-shadow: 0px -6px 13px 0px rgba(0, 0, 0, 0.1);
    }

    &.keyboard-open {
      z-index: -1 !important;
    }

    ion-content {
      --offset-top: 30px !important;
      --background: #{$z2s-color-white};
    }

    &::part(content) {
      border-radius: 15px 15px 0 0;
    }

    &::part(handle) {
      width: 80px;
      height: 4px;
      top: 10px;
      background: #DEDEDE;
    }

    &::part(handle)::before {
      width: 80px;
      height: 4px;
      padding: 60px 105px;
    }

    &.stock-modal {
      &::part(handle)::before {
        width: 80px;
        height: 4px;
        padding: 60px 140px;
      }
    }

    &.personalized-modal {
      &::part(handle)::before {
        width: 80px;
        height: 4px;
        padding: 30px 80px;
      }
    }

    &.modal-place {
      &::part(handle)::before {
        width: 80px;
        height: 4px;
        padding: 20px 60px;
      }
    }

    &.modal-max-size-small {
      --bottom-offset: calc(100vh * (1 - 0.25));
    }

    &.modal-max-size-half {
      --bottom-offset: calc(100vh * (1 - 0.5));
    }

    &.modal-max-size-big {
      --bottom-offset: calc(100vh * (1 - 0.75));
    }

    &.modal-max-size-full {
      --bottom-offset: calc(100vh * (1 - 0.90));
    }

    //&.personalized-modal {
    //  --bottom-offset: calc(100vh * (1 - 0.75));
    //}

    &.modal-initial-size-qrsize {
      --bottom-offset: calc(100vh * (1 - var(--qrsize)));
    }

    ion-content {
      &::part(scroll) {
        margin-bottom: var(--bottom-offset);
      }

      [slot="fixed"] {
        bottom: var(--bottom-offset);
      }
    }
  }
}

/* Loader */

.loading-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-backdrop-color, #000);
  z-index: 1;
  .loading-spinner {
    position: relative;
    border: 6px solid $z2s-color-white;
    border-top: 6px solid $z2s-color-primary;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    animation: spin 1.5s linear infinite;

    &:after {
      border-radius: 50%;
      width: 6px;
      height: 6px;
      content: "";
      background: $z2s-color-primary;
      display: block;
      position: absolute;
      bottom: 30.1px;
      left: 30.3px;
    }

    &:before {
      border-radius: 50%;
      width: 6px;
      height: 6px;
      content: "";
      background: $z2s-color-primary;
      display: block;
      position: absolute;
      bottom: 30.1px;
      left: 0.6px;
    }
  }
}

.custom-loading {
  --background: none;

  .loading-wrapper {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    ion-spinner {
      display: none !important;
    }
  }
}

.custom-loading.hide-loading {
  .loading-wrapper {
    animation: fade-out 0.5s ease-out forwards;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

//party in dashboard & in modal

.party {
  padding-bottom: 12px;
  .party-top {
    height: 178px;
    background-repeat: no-repeat !important;
    background-size: cover !important;;

    .party-icon {
      width: 76px;
      height: 76px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .points {
      margin-top: 6px;
    }
  }

  .party-bottom {
    margin-top: 5px;

    .party-bottom-underline {
      text-decoration: underline;
    }
  }
}

.bottom-panel-close {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $z2s-color-card !important;
}

.input {
  &-size {
    &-small {
      app-segment {
        ion-segment-button {
          min-width: 0;
        }
      }
    }
  }

  &-label {
    @include text-12-regular;
    color: $z2s-color-label;
    margin-bottom: 5px;
  }
}

.segment-style {
  &-input {
    height: 46px;
    border-radius: 6px;
    border: 1px solid #E9E9E9;
    padding: 0.1px;

    ion-segment {
      height: 100% !important;

      ion-segment-button {
        height: calc(100% - 8px) !important;
        background-color: transparent;
        --border-radius: 6px !important;
      }
    }
  }

  &-tab {
    height: 39px;
    border-radius: 50px;
    border: none;

    ion-segment {
      height: 100% !important;
      background-color: $z2s-color-white;
      ion-segment-button {
        height: calc(100% - 6px) !important;
        margin: 3px !important;
        font-size: 0.75rem !important;
        --border-radius: 50px !important;
        --color: #{$z2s-color-grey-light} !important;
      }
    }
  }
}

ion-modal.modal-select,
ion-modal.modal-error {
  --width: calc(100% - 40px);
  --min-width: 280px;
  --height: fit-content;
  --backdrop-opacity: 0.5 !important;

  .ion-page {
    // padding: 20px 0;
  }

  &::part(content) {
    border-radius: 10px;
  }
}

.tippy-box[data-animation="tooltip"][data-state="hidden"] {
  opacity: 0;
  transform: translateY(5px);
}

.z2s-tooltip {
  .tippy-content {
    padding: 15px;
    color: $z2s-color-white;
    background: $z2s-color-background-dark;
    @include text-12-regular;
    border-radius: 5px;
  }

  .tippy-arrow {
    color: $z2s-color-background-dark;
  }
}

ion-input {
  --padding-start: 0;
  --padding-end: 0;
  min-height: 46px !important;
  height: 46px;
  overflow: hidden;

  input {
    margin-top: 0px !important;
    padding: 0 15px !important;
  }


  .input-wrapper{
  }

  .label-text-wrapper,
  .input-highlight {
    display: none !important;
  }
  --border-radius: 6px;
  &.ion-color-light{
    outline: 1px solid $z2s-color-border;
    background: #{$z2s-color-background-light};
  }
  &.ion-color-dark{
    outline: 1px solid $z2s-color-grey;
    background: #{$z2s-color-background-dark};
  }
}

.container-bottom {
  padding-bottom: 95px !important;
}

.hide {
  display: none;
}

ion-modal {
  &::part(handle) {
    &::before {
      padding: 9px 0;
    }
  }
}

.swiper-3d .swiper-slide-shadow {
  background: transparent !important;
}

.online-chat-root-Verbox .widget-root .widget-header {
  padding-top: 3.5em !important;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
  user-select: none;
}

.input-clear-icon {
  justify-content: flex-end !important;
  width: 45px !important;
  padding-right: 12px !important;
}

.full-width-menu {
  --width: 100%;
}

.header-md {
  box-shadow: 0 3px 5px 0 rgba(84, 84, 84, 0.05);
}

ion-refresher.refresher-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bonus-el {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 5px 10px;
  &.bonus-plus {
    background: #e1f9e5;
  }
  &.bonus-minus {
    background: #ebebeb;
  }
}
