@mixin m-5 {
  margin: 5px;
}

@mixin mt-5 {
  margin-top: 5px;
}

@mixin mb-5 {
  margin-bottom: 5px;
}

@mixin ml-5 {
  margin-left: 5px;
}

@mixin mr-5 {
  margin-right: 5px;
}

.m-5 {
  @include m-5;
}

.ml-5 {
  @include ml-5;
}

.mr-5 {
  @include mr-5;
}

.mt-5 {
  @include mt-5;
}

.mb-5 {
  @include mb-5;
}

.mx-5 {
  @include ml-5;
  @include mr-5;
}

.my-5 {
  @include mt-5;
  @include mb-5;
}

@mixin p-5 {
  padding: 5px;
}

@mixin pt-5 {
  padding-top: 5px;
}

@mixin pb-5 {
  padding-bottom: 5px;
}

@mixin pl-5 {
  padding-left: 5px;
}

@mixin pr-5 {
  padding-right: 5px;
}

.p-5 {
  @include p-5;
}

.pl-5 {
  @include pl-5;
}

.pr-5 {
  @include pr-5;
}

.pt-5 {
  @include pt-5;
}

.pb-5 {
  @include pb-5;
}

.px-5 {
  @include pl-5;
  @include pr-5;
}

.py-5 {
  @include pt-5;
  @include pb-5;
}

@mixin m-10 {
  margin: 10px;
}

@mixin mt-10 {
  margin-top: 10px;
}

@mixin mb-10 {
  margin-bottom: 10px;
}

@mixin ml-10 {
  margin-left: 10px;
}

@mixin mr-10 {
  margin-right: 10px;
}

.m-10 {
  @include m-10;
}

.ml-10 {
  @include ml-10;
}

.mr-10 {
  @include mr-10;
}

.mt-10 {
  @include mt-10;
}

.mb-10 {
  @include mb-10;
}

.mx-10 {
  @include ml-10;
  @include mr-10;
}

.my-10 {
  @include mt-10;
  @include mb-10;
}

@mixin p-10 {
  padding: 10px;
}

@mixin pt-10 {
  padding-top: 10px;
}

@mixin pb-10 {
  padding-bottom: 10px;
}

@mixin pl-10 {
  padding-left: 10px;
}

@mixin pr-10 {
  padding-right: 10px;
}

.p-0 {
  padding: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-10 {
  @include p-10;
}

.pl-10 {
  @include pl-10;
}

.pr-10 {
  @include pr-10;
}

.pt-10 {
  @include pt-10;
}

.pb-10 {
  @include pb-10;
}

.px-10 {
  @include pl-10;
  @include pr-10;
}

.py-10 {
  @include pt-10;
  @include pb-10;
}

@mixin m-15 {
  margin: 15px;
}

@mixin mt-15 {
  margin-top: 15px;
}

@mixin mb-15 {
  margin-bottom: 15px;
}

@mixin ml-15 {
  margin-left: 15px;
}

@mixin mr-15 {
  margin-right: 15px;
}

.m-15 {
  @include m-15;
}

.ml-15 {
  @include ml-15;
}

.mr-15 {
  @include mr-15;
}

.mt-15 {
  @include mt-15;
}

.mb-15 {
  @include mb-15;
}

.mx-15 {
  @include ml-15;
  @include mr-15;
}

.my-15 {
  @include mt-15;
  @include mb-15;
}

@mixin p-15 {
  padding: 15px;
}

@mixin pt-15 {
  padding-top: 15px;
}

@mixin pb-15 {
  padding-bottom: 15px;
}

@mixin pl-15 {
  padding-left: 15px;
}

@mixin pr-15 {
  padding-right: 15px;
}

.p-15 {
  @include p-15;
}

.pl-15 {
  @include pl-15;
}

.pr-15 {
  @include pr-15;
}

.pt-15 {
  @include pt-15;
}

.pb-15 {
  @include pb-15;
}

.px-15 {
  @include pl-15;
  @include pr-15;
}

.py-15 {
  @include pt-15;
  @include pb-15;
}

@mixin m-20 {
  margin: 20px;
}

@mixin mt-20 {
  margin-top: 20px;
}

@mixin mb-20 {
  margin-bottom: 20px;
}

@mixin ml-20 {
  margin-left: 20px;
}

@mixin mr-20 {
  margin-right: 20px;
}

.m-20 {
  @include m-20;
}

.ml-20 {
  @include ml-20;
}

.mr-20 {
  @include mr-20;
}

.mt-20 {
  @include mt-20;
}

.mb-20 {
  @include mb-20;
}

.mx-20 {
  @include ml-20;
  @include mr-20;
}

.my-20 {
  @include mt-20;
  @include mb-20;
}

@mixin p-20 {
  padding: 20px;
}

@mixin pt-20 {
  padding-top: 20px;
}

@mixin pb-20 {
  padding-bottom: 20px;
}

@mixin pl-20 {
  padding-left: 20px;
}

@mixin pr-20 {
  padding-right: 20px;
}

.p-20 {
  @include p-20;
}

.pl-20 {
  @include pl-20;
}

.pr-20 {
  @include pr-20;
}

.pt-20 {
  @include pt-20;
}

.pb-20 {
  @include pb-20;
}

.px-20 {
  @include pl-20;
  @include pr-20;
}

.py-20 {
  @include pt-20;
  @include pb-20;
}

@mixin m-25 {
  margin: 25px;
}

@mixin mt-25 {
  margin-top: 25px;
}

@mixin mb-25 {
  margin-bottom: 25px;
}

@mixin ml-25 {
  margin-left: 25px;
}

@mixin mr-25 {
  margin-right: 25px;
}

.m-25 {
  @include m-25;
}

.ml-25 {
  @include ml-25;
}

.mr-25 {
  @include mr-25;
}

.mt-25 {
  @include mt-25;
}

.mb-25 {
  @include mb-25;
}

.mx-25 {
  @include ml-25;
  @include mr-25;
}

.my-25 {
  @include mt-25;
  @include mb-25;
}

@mixin p-25 {
  padding: 25px;
}

@mixin pt-25 {
  padding-top: 25px;
}

@mixin pb-25 {
  padding-bottom: 25px;
}

@mixin pl-25 {
  padding-left: 25px;
}

@mixin pr-25 {
  padding-right: 25px;
}

.p-25 {
  @include p-25;
}

.pl-25 {
  @include pl-25;
}

.pr-25 {
  @include pr-25;
}

.pt-25 {
  @include pt-25;
}

.pb-25 {
  @include pb-25;
}

.px-25 {
  @include pl-25;
  @include pr-25;
}

.py-25 {
  @include pt-25;
  @include pb-25;
}

@mixin m-30 {
  margin: 30px;
}

@mixin mt-30 {
  margin-top: 30px;
}

@mixin mb-30 {
  margin-bottom: 30px;
}

@mixin ml-30 {
  margin-left: 30px;
}

@mixin mr-30 {
  margin-right: 30px;
}

.m-30 {
  @include m-30;
}

.ml-30 {
  @include ml-30;
}

.mr-30 {
  @include mr-30;
}

.mt-30 {
  @include mt-30;
}

.mb-30 {
  @include mb-30;
}

.mx-30 {
  @include ml-30;
  @include mr-30;
}

.my-30 {
  @include mt-30;
  @include mb-30;
}

@mixin p-30 {
  padding: 30px;
}

@mixin pt-30 {
  padding-top: 30px;
}

@mixin pb-30 {
  padding-bottom: 30px;
}

@mixin pl-30 {
  padding-left: 30px;
}

@mixin pr-30 {
  padding-right: 30px;
}

.p-30 {
  @include p-30;
}

.pl-30 {
  @include pl-30;
}

.pr-30 {
  @include pr-30;
}

.pt-30 {
  @include pt-30;
}

.pb-30 {
  @include pb-30;
}

.px-30 {
  @include pl-30;
  @include pr-30;
}

.py-30 {
  @include pt-30;
  @include pb-30;
}

@mixin m-40 {
  margin: 40px;
}

@mixin mt-40 {
  margin-top: 40px;
}

@mixin mb-40 {
  margin-bottom: 40px;
}

@mixin ml-40 {
  margin-left: 40px;
}

@mixin mr-40 {
  margin-right: 40px;
}

.m-40 {
  @include m-40;
}

.ml-40 {
  @include ml-40;
}

.mr-40 {
  @include mr-40;
}

.mt-40 {
  @include mt-40;
}

.mb-40 {
  @include mb-40;
}

.mx-40 {
  @include ml-40;
  @include mr-40;
}

.my-40 {
  @include mt-40;
  @include mb-40;
}

@mixin p-40 {
  padding: 40px;
}

@mixin pt-40 {
  padding-top: 40px;
}

@mixin pb-40 {
  padding-bottom: 40px;
}

@mixin pl-40 {
  padding-left: 40px;
}

@mixin pr-40 {
  padding-right: 40px;
}

.p-40 {
  @include p-40;
}

.pl-40 {
  @include pl-40;
}

.pr-40 {
  @include pr-40;
}

.pt-40 {
  @include pt-40;
}

.pb-40 {
  @include pb-40;
}

.px-40 {
  @include pl-40;
  @include pr-40;
}

.py-40 {
  @include pt-40;
  @include pb-40;
}

.remove-last-padding {
  .pb-5:last-child, .pb-10:last-child, .pb-15:last-child, .pb-20:last-child, .pb-25:last-child, .pb-30:last-child {
    padding-bottom: 0;
  }
}
