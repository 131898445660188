$grid-columns: 6;
$grid-gutter: 20px;

:root {
  --app-grid-gutter-x: #{$grid-gutter};
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--app-grid-gutter-x) * -.5);
  margin-left: calc(var(--app-grid-gutter-x) * -.5);

  & > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--app-grid-gutter-x) * .5);
    padding-left: calc(var(--app-grid-gutter-x) * .5);
  }
}

.col {
  width: 100%;
  max-width: 100%;
  flex: 1 0 0;
  padding-left: calc(var(--app-grid-gutter-x) * .5);
  padding-right: calc(var(--app-grid-gutter-x) * .5);
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  width: calc(100% * (1 / 6));
}

.col-2 {
  width: calc(100% * (2 / 6));
}

.col-3 {
  width: calc(100% * (3 / 6));
}

.col-4 {
  width: calc(100% * (4 / 6));
}

.col-5 {
  width: calc(100% * (5 / 6));
}

.col-6 {
  width: calc(100% * (6 / 6));
}

.height-100-p {
  height: 100%;
}

.display-block {
  display: block;
}

.text-align-center {
  text-align: center;
}

.text-align-start {
  text-align: start;
}

.text-align-end {
  text-align: end;
}

.display-none {
  display: none;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-column-reverse {
  flex-direction: column-reverse;
}

.display-inline-block {
  display: inline-block;
}

.display-flex {
  display: flex;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-stretch {
  align-self: stretch;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-auto {
  align-self: auto;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

.wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-baseline {
  align-items: baseline;
}

.flex-1 {
  flex: 1;
}

.gap-5 {
  gap: 5px;
}

.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.gap-20-0 {
  gap: 20px 0;
}

.gap-10-30 {
  gap: 10px 30px;
}
/*# sourceMappingURL=flex-utils.css.map */

