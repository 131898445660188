$z2s-color-background-light: #F9F9F9;
$z2s-color-background-dark: #000000;
$z2s-color-primary: #4CD964;
$z2s-color-primary-hover: #3CC353;
$z2s-color-white: #FFFFFF;
$z2s-color-error: #E63946;
$z2s-color-border: #E9E9E9;
$z2s-color-label: #979797;
$z2s-color-card: #F3F3F3;
$z2s-color-card-hover: #EBEBEB;
$z2s-color-orange: #FFA959;
$z2s-color-orange-light: #FFEBE0;
$z2s-color-yellow: #FFC467;
$z2s-color-yellow-light: #FFF1DB;
$z2s-color-purple: #535DB7;
$z2s-color-purple-light: #EAE8F9;
$z2s-color-green: #4AB799;
$z2s-color-green-light: #E1F9E5;
$z2s-color-black: #161817;
$z2s-color-grey: #696969;
$z2s-color-grey-light: #8B8B8B;

$default-font-family: 'Inter', serif;

$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;

$z2s-shadow-popup: 0 4px 0 12px rgba(72, 72, 72, 0.10);

:root {
  --ion-font-family: #{$default-font-family};
  --ion-color-primary: #{$z2s-color-primary};
  --ion-background-color: #{$z2s-color-background-light};
  --ion-backdrop-color: #{$z2s-color-black};


  --ion-color-dark: #{$z2s-color-background-dark};
  --ion-color-dark-contrast: #{$z2s-color-white};

}


$border-radius: 1000px;

:root {
  --background-light: #{$z2s-color-background-light};
  --background-dark: #{$z2s-color-background-dark};
  --primary: #{$z2s-color-primary};
  --primary-hover: #{$z2s-color-primary-hover};
  --white: #{$z2s-color-white};
  --error: #{$z2s-color-error};
  --border: #{$z2s-color-border};
  --label: #{$z2s-color-label};
  --card: #{$z2s-color-card};
  --card-hover: #{$z2s-color-card-hover};
  --orange: #{$z2s-color-orange};
  --orange-light: #{$z2s-color-orange-light};
  --yellow: #{$z2s-color-yellow};
  --yellow-light: #{$z2s-color-yellow-light};
  --purple: #{$z2s-color-purple};
  --purple-light: #{$z2s-color-purple-light};
  --green: #{$z2s-color-green};
  --green-light: #{$z2s-color-green-light};
  --black: #{$z2s-color-black};
  --grey: #{$z2s-color-grey};
  --grey-light: #{$z2s-color-grey-light};
}
