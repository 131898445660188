@import "variables";

@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url('../assets/fonts/Inter/Inter.ttf') format('truetype');
  font-weight: $font-weight-regular $font-weight-medium $font-weight-semi-bold $font-weight-bold;
}

@mixin text-h1 {
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 1.25rem;
  line-height: 24px;
}

@mixin text-h2 {
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 1.125rem;
  line-height: 22px;
}

@mixin text-h3 {
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  font-size: 1rem;
  line-height: 19px;
}

@mixin text-button {
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  font-size: 1rem;
  line-height: 19px;
  letter-spacing: 0.2px;
}

@mixin text-14-bold {
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 0.875rem;
  line-height: 17px;
}

@mixin text-14-semi-bold {
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  font-size: 0.875rem;
  line-height: 17px;
}

@mixin text-14-medium {
  font-style: normal;
  font-weight: $font-weight-medium;
  font-size: 0.875rem;
  line-height: 17px;
}

@mixin text-14-regular {
  font-style: normal;
  font-weight: $font-weight-regular;
  font-size: 0.875rem;
  line-height: 17px;
}

@mixin text-12-semi-bold {
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  font-size: 0.75rem;
  line-height: 15px;
}

@mixin text-12-medium {
  font-style: normal;
  font-weight: $font-weight-medium;
  font-size: 0.75rem;
  line-height: 15px;
}

@mixin text-12-regular {
  font-style: normal;
  font-weight: $font-weight-regular;
  font-size: 0.75rem;
  line-height: 15px;
}

@mixin text-10-semi-bold {
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  font-size: 0.625rem;
  line-height: 12px;
  letter-spacing: 0.09px;
}

@mixin text-10-medium {
  font-style: normal;
  font-weight: $font-weight-medium;
  font-size: 0.625rem;
  line-height: 12px;
  letter-spacing: 0.09px;
}

@mixin text-10-regular {
  font-style: normal;
  font-weight: $font-weight-regular;
  font-size: 0.680rem;
  line-height: 12px;
  letter-spacing: 0.09px;
}
