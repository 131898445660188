@import "src/theme/variables.scss";
@import "src/theme/spacing.scss";
@import "src/theme/typography.scss";
@import "src/theme/grid.scss";

.app-container {
  flex-direction: column;
  padding: 20px 20px;
  &:not(.hide) {
    display: flex;
  }
}

.content-with-bottom {
  height: calc(100% - 90px);
}

// region color


.color-background-light {
  color: $z2s-color-background-light;
}

.color-background-dark {
  color: $z2s-color-background-dark;
}

.color-primary {
  color: $z2s-color-primary;
}

.color-primary-hover {
  color: $z2s-color-primary-hover;
}

.color-white {
  color: $z2s-color-white;
}

.color-error {
  color: $z2s-color-error;
}

.color-border {
  color: $z2s-color-border;
}

.color-label {
  color: $z2s-color-label;
}

.color-card {
  color: $z2s-color-card;
}

.color-card-hover {
  color: $z2s-color-card-hover;
}

.color-orange {
  color: $z2s-color-orange;
}

.color-orange-light {
  color: $z2s-color-orange-light;
}

.color-yellow {
  color: $z2s-color-yellow;
}

.color-yellow-light {
  color: $z2s-color-yellow-light;
}

.color-purple {
  color: $z2s-color-purple;
}

.color-purple-light {
  color: $z2s-color-purple-light;
}

.color-green {
  color: $z2s-color-green;
}

.color-green-light {
  color: $z2s-color-green-light;
}

.color-black {
  color: $z2s-color-black;
}

.color-grey {
  color: $z2s-color-grey;
}

.color-grey-light {
  color: $z2s-color-grey-light;
}

// endregion

// region bg
.bg-background-light {
  background: $z2s-color-background-light;
}

.bg-background-dark {
  background: $z2s-color-background-dark;
}

.bg-primary {
  background: $z2s-color-primary;
}

.bg-primary-hover {
  background: $z2s-color-primary-hover;
}

.bg-white {
  background: $z2s-color-white;
}

.bg-error {
  background: $z2s-color-error;
}

.bg-border {
  background: $z2s-color-border;
}

.bg-label {
  background: $z2s-color-label;
}

.bg-card {
  background: $z2s-color-card;
}

.bg-card-hover {
  background: $z2s-color-card-hover;
}

.bg-orange {
  background: $z2s-color-orange;
}

.bg-orange-light {
  background: $z2s-color-orange-light;
}

.bg-yellow {
  background: $z2s-color-yellow;
}

.bg-yellow-light {
  background: $z2s-color-yellow-light;
}

.bg-purple {
  background: $z2s-color-purple;
}

.bg-purple-light {
  background: $z2s-color-purple-light;
}

.bg-green {
  background: $z2s-color-green;
}

.bg-green-light {
  background: $z2s-color-green-light;
}

.bg-black {
  background: $z2s-color-black;
}

.bg-grey {
  background: $z2s-color-grey;
}

.bg-grey-light {
  background: $z2s-color-grey-light;
}

// endregion

// region text

.text-h1 {
  @include text-h1;
}

.text-h2 {
  @include text-h2;
}

.text-h3 {
  @include text-h3;
}

.text-button {
  @include text-button;
}

.text-14-bold {
  @include text-14-bold;
}

.text-14-semi-bold {
  @include text-14-semi-bold;
}

.text-14-medium {
  @include text-14-medium;
}

.text-14-regular {
  @include text-14-regular;
}

.text-12-semi-bold {
  @include text-12-semi-bold;
}

.text-12-medium {
  @include text-12-medium;
}

.text-12-regular {
  @include text-12-regular;
}

.text-10-semi-bold {
  @include text-10-semi-bold;
}

.text-10-medium {
  @include text-10-medium;
}

.text-10-regular {
  @include text-10-regular;
}

// endregion

// region border-radius

@mixin radius-10 {
  border-radius: 10px;
}

@mixin radius-15 {
  border-radius: 15px;
}

@mixin radius-20 {
  border-radius: 20px;
}

@mixin radius-25 {
  border-radius: 25px;
}

@mixin radius-30 {
  border-radius: 30px;
}

.radius-10 {
  @include radius-10;
}

.radius-15 {
  @include radius-15;
}

.radius-20 {
  @include radius-20;
}

.radius-25 {
  @include radius-25;
}

.radius-30 {
  @include radius-30;
}

// endregion
